import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

import {
  NemoColumn,
  VolumenColumn,
  MinMaxVolColumn,
  PipColumn,
  MarginColumn,
  SpreadColumn,
  SwapColumn,
  NameColumn,
  MarginCurrencyColumn,
  ActionColumn,
} from "./columns"

import BaseInformation from "./detail-information/base"
import Schedule from "./detail-information/schedule"
import Dividend from "./detail-information/dividend"
import Contract from "./detail-information/contract"
import RatioAdr from "./detail-information/ratio-adr"
import Exchange from "./detail-information/exchange"
import ShortPosition from "./detail-information/short-position"
import Action from "../ui/images/action"
import ActionOpen from "../ui/images/action-open"
import { trackEvent } from "../../helpers/tracker"

const columnsTypeAccount: ColumnsTypes = {
  start: {
    spread: "spread",
    min_volumen: "min_volumen",
    max_volumen: "max_volumen",
  },
  pro: {
    min_volumen: "pro_min_volumen",
    max_volumen: "pro_max_volumen",
    spread: "pro_spread",
  },
  premium: {
    min_volumen: "premium_min_volumen",
    max_volumen: "premium_max_volumen",
    spread: "premium_spread",
  },
}

const RowDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
  min-height: 64px;
  background-color: #ffffff;
  padding: 0 10px;
  &.open {
    min-height: 128px;
    box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
`

const DefaultColumns = styled.div`
  display: flex;
  cursor: pointer;
  min-height: 64px;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 14px;
    justify-content: space-between;
    height: auto;
  }
`

const DetailInformation = styled.div`
  display: flex;
  font-size: 12px;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 12px;
  border-top: 1px solid #cedef1;
  @media (max-width: 1024px) {
    flex-direction: column;
    border-top: 2px solid #cedef1;
  }
`

const Divisor = styled.div`
  border: 0.5px solid #cedef1;
  @media (max-width: 1024px) {
    display: none;
  }
`

const getSpread = (typeAccount: string, instrument: Instrument) => {
  if (typeAccount === "start") {
    return instrument[columnsTypeAccount["start"].spread]
  } else {
    const spreadPro = instrument[columnsTypeAccount["pro"].spread]
    const spreadPremium = instrument[columnsTypeAccount["premium"].spread]

    return `${spreadPro} / ${spreadPremium}`
  }
}

type RowTypeProps = {
  instrument: Instrument
  typeAccount: string
  country: string
}

const Row = ({ instrument, typeAccount, country }: RowTypeProps) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    setShow(false)
  }, [instrument])

  const toggleDetails = () => {
    if (!show) {
      trackEvent(`aspectos-operativos-kt:instrument:detail`, {
        nemo: instrument.nemo,
      })
    }
    setShow(!show)
  }

  const spread = getSpread(typeAccount, instrument)

  return (
    <RowDiv key={`idx-${instrument.nemo}`} className={show ? "open" : "closed"}>
      <DefaultColumns onClick={toggleDetails}>
        <NemoColumn>{instrument.nemo}</NemoColumn>
        <NameColumn>{instrument.name}</NameColumn>
        <MarginCurrencyColumn>
          {instrument.margin_currency}
        </MarginCurrencyColumn>
        <VolumenColumn>{instrument.volumen_in_currency}</VolumenColumn>
        <MinMaxVolColumn>
          {instrument[columnsTypeAccount[typeAccount].min_volumen]} /{" "}
          {instrument[columnsTypeAccount[typeAccount].max_volumen]}
        </MinMaxVolColumn>
        <PipColumn>{instrument.pip_value}</PipColumn>
        <MarginColumn>{instrument.margin}</MarginColumn>
        <SpreadColumn>{spread}</SpreadColumn>
        <SwapColumn>
          {instrument.buy_swap} / {instrument.sell_swap}
        </SwapColumn>
        <ActionColumn className={show ? "open" : "closed"}>
          {!show && <Action />}
          {show && <ActionOpen />}
        </ActionColumn>
      </DefaultColumns>
      {show && (
        <DetailInformation>
          <BaseInformation
            instrument={instrument}
            columnsTypeAccount={columnsTypeAccount}
            typeAccount={typeAccount}
          />
          <Schedule instrument={instrument} country={country} />
          <RatioAdr instrument={instrument} Divisor={Divisor} />
          <Contract instrument={instrument} Divisor={Divisor} />
          <Exchange instrument={instrument} Divisor={Divisor} />
          <ShortPosition instrument={instrument} Divisor={Divisor} />
        </DetailInformation>
      )}
    </RowDiv>
  )
}

export default Row
